import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import AddClient from "../components/addClient"

import Login from "./login"
import { withAuthenticator } from "aws-amplify-react"

const AddClientPage = () => (
  <Layout type={2}>
    <Menu type={2} btype={2} />
    <AddClient />
    <Footer type={3} />
  </Layout>
)

export default withAuthenticator(AddClientPage, false, [<Login />])
