import React, { useState } from "react"
import styles from "./addClient.module.css"
import { Link } from "gatsby"

const AddClient = () => {
  const [submitStatus, setSubmitStatus] = useState("")
  const [name, setName] = useState("")
  // const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")

  const onNameChange = input => {
    setName(input.target.value)
    setSubmitStatus("")
  }
  // const onPhoneNumberChange = input => {
  //   setPhoneNumber(input.target.value)
  //   setSubmitStatus("")
  // }
  const onEmailChange = input => {
    setEmail(input.target.value)
    setSubmitStatus("")
  }

  const handleAddClient = () => {
    console.log("click")
    fetch("/.netlify/functions/addClient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        name: name,
        company: "https://www.finsimpl.com/",
        clientEmail: email,
      }),
    })
      .then(res => {
        console.log(res)
        clearInput()
        setSubmitStatus("success")
      })
      .catch(err => {
        console.log(err)
        setSubmitStatus("fail")
      })
  }

  const clearInput = () => {
    document.getElementById("c_name").value = ""
    document.getElementById("c_email").value = ""
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Link to="/dashboard">
          <div className={[styles.editButton, styles.button].join(" ")}>
            <div className={styles.button_text}>Back</div>
          </div>
        </Link>
        <h1 className={styles.title_text}>Add Client</h1>
      </div>

      <div className={styles.content}>
        {submitStatus === "success" ? (
          <div className={styles.success}>
            <span className={styles.bold}>Success!</span> Your email has been
            sent.
          </div>
        ) : submitStatus === "fail" ? (
          <div className={styles.fail}>
            <span className={styles.bold}>Error!</span> Something went wrong,
            please try again.
          </div>
        ) : null}

        <div className={styles.attribute}>
          <span className={styles.attribute_text}>Client Name:</span>
          &emsp;&emsp;&emsp;&nbsp;&nbsp;
          <span className={styles.a_text}>
            <input
              name="profile_input"
              id="c_name"
              className={styles.input_text}
              onChange={onNameChange}
            />
          </span>
        </div>
        <div className={styles.attribute}>
          <span className={styles.attribute_text}>Email:</span>
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;
          <span className={styles.a_text}>
            <input
              name="profile_input"
              id="c_email"
              className={styles.input_text}
              type="email"
              onChange={onEmailChange}
            />
          </span>
        </div>
        {/* <div className={styles.attribute}>
          <span className={styles.attribute_text}>Phone Number:</span>
          &emsp;
          <span className={styles.a_text}>
            <input
              name="profile_input"
              className={styles.input_text}
              onChange={onPhoneNumberChange}
            />
          </span>
        </div> */}
        <div className={styles.box}>
          <div
            onClick={handleAddClient}
            className={styles.button}
            role="button"
            disabled={!(name && email)}
          >
            <div className={styles.button_text}>Add Client</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddClient
